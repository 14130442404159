<template>
  <div class="coianter">
    <div class="idteail">
      <div class="idtname">居民建筑能效测评汇总表</div>
      <div class="back"  @click="goback()">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="dtcontent">
  <div class="tableform">
      <el-form ref="form" :model="project" label-width="180px">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="gezi">
                <el-form-item label="项目名称：">
                  {{ project.name }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="建筑面积（m²）/层数：">
                  {{ project.building_area }}/{{project.floor}}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="建设单位：">
                  {{ project.build_company }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="施工单位：">
                  <el-input v-model="project.work_build" placeholder="请输入内容"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="gezi">
                <el-form-item label="项目地址：">
                  {{ project.address }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="气候区域：">
                  <span v-if="project.weather == 1">夏热冬冷</span>
                  <span v-if="project.weather == 2">寒冷</span>
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="设计单位：">
                  <el-input v-model="project.design_company" placeholder="请输入内容"></el-input>
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="监理单位：">
                  <el-input v-model="project.manger_company" placeholder="请输入内容"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-form ref="formtable" :model="formtable" label-width="180px">
      <div class="engerytable">
        <table border="1" cellspacing="0" width="100%">
          <tbody>
            <tr>
              <th colspan="8" scope="col" style="height: 40px">
                &nbsp;测评内容
              </th>
              <th scope="col">&nbsp;测评方法</th>
              <th scope="col">&nbsp;测评结果</th>
            </tr>
            <tr>
              <th rowspan="3" scope="row">基础类&nbsp;</th>
              <td colspan="3"  style="background-color:#a3c1dd2d">单位建筑面积供暖能耗（kWh/m2）</td>
              <td colspan="2">
                <textarea
                  type="text"
                  v-model="formtable.heatEnergy"
                  placeholder="请输入内容"
                />
              </td>
              <td colspan="2" rowspan="3"  style="background-color:#a3c1dd2d">&nbsp; 相对节能率（%）</td>
              <td rowspan="3">
                <el-select
                  v-model="formtable.evaluationWay_heatEnergy"
                  placeholder="软件评估"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                </el-select>
              </td>
              <td rowspan="3">
                <textarea
                  type="text"
                  v-model="formtable.evaInstitu_heatEnergy"
                  placeholder="请输入内容"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row"  style="background-color:#a3c1dd2d">单位建筑面积空调能耗（kWh/m2）</td>
              <td colspan="2">
                <textarea
                  type="text"
                  v-model="formtable.airEnergy"
                  placeholder="请输入内容"
                />
              </td>
              <!-- <td>
                   <el-select v-model="formtable.evaluationWay_airEnergy" placeholder="选择测评方法" clearable>
                   <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                  </el-select>

              </td> -->
              <!-- <td>
                <input type="text" v-model="formtable.evaInstitu_airEnergy" placeholder="请输入内容" />
              </td> -->
            </tr>
            <tr>
              <td colspan="3" scope="row"  style="background-color:#a3c1dd2d">单位建筑面积全年能耗量（kWh/m² )</td>
              <td colspan="2">
                <textarea
                  type="text"
                  v-model="formtable.buildEnergy"
                  placeholder="请输入内容"
                />
              </td>
              <!-- <td>
                      <el-select v-model="formtable.evaluationWay_buildEnergy" placeholder="选择测评方法" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                  </el-select>
              </td>
              <td>
                <input type="text" v-model="formtable.evaInstitu_buildEnergy" placeholder="请输入内容"/>
              </td> -->
            </tr>
            <tr>
              <th
                :rowspan="
                  30 + jizu5.length + jizu1.length + jizu3.length + jizu6.length
                "
                scope="row"
              >
                &nbsp;规定项
              </th>
              <th rowspan="5">&nbsp;围护结构</th>
              <td colspan="2"  style="background-color:#a3c1dd2d">外窗气密性</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.wallGas"
                  placeholder="请输入内容"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_wallGas"
                  placeholder="文件审查"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                 <el-select
                  v-model="formtable.evaInstitu_wallGas"
                  clearable
                  placeholder="符合要求"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_wallGas"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2"  style="background-color:#a3c1dd2d">热桥部位（夏热冬冷/寒冷地区）</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.hotPart"
                  placeholder="请输入内容"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_hotPart"
                  placeholder="文件审查；现场检查"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_wallGas"
                  clearable
                  placeholder="符合要求"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_hotPart"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row"  style="background-color:#a3c1dd2d">门窗保温（夏热冬冷/寒冷地区）</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.doorHeat"
                  placeholder="请输入内容"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_doorHeat"
                  placeholder="文件审查；现场检查"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_wallGas"
                  clearable
                  placeholder="符合要求"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_doorHeat"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row"  style="background-color:#a3c1dd2d">建筑外窗（夏热冬冷/寒冷地区）</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.wallOpenArea"
                  placeholder="请输入内容"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_wallOpenArea"
                  placeholder="文件审查；现场检查"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                 <el-select
                  v-model="formtable.evaInstitu_wallGas"
                  clearable
                  placeholder="符合要求"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_wallOpenArea"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row"  style="background-color:#a3c1dd2d">南向外遮阳</td>
              <td colspan="4">

                <textarea
                  type="text"
                  v-model="formtable.outShade"
                  placeholder="请输入内容"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_outShade"
                  placeholder="文件审查；现场检查"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_outShade"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_outShade"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td
                :rowspan="
                  6 + jizu5.length + jizu1.length + jizu3.length + jizu6.length
                "
                scope="row"
                 style="background-color:#a3c1dd2d"
              >
                &nbsp;冷 热 源 及 空 调 系 统
              </td>
              <td colspan="2" scope="row"  style="background-color:#a3c1dd2d">&nbsp; 空调冷源</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.coldSource"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_coldSource"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_coldSource"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_coldSource"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row"  style="background-color:#a3c1dd2d">供暖热源&nbsp;</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.hotSource"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_hotSource"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_hotSource"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_hotSource"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2" :rowspan="jizu5.length + 1" scope="row"  style="background-color:#a3c1dd2d">
                房间空气调节器
                <div class="zengjia">
                  <el-button type="primary" @click="addjizu(1)">
                    增加 房间空气调节器
                  </el-button>
                </div>
              </td>
              <td  style="background-color:#a3c1dd2d">&nbsp;类型</td>
              <td  style="background-color:#a3c1dd2d">&nbsp; 单机额定制冷量（kW）</td>
              <td  style="background-color:#a3c1dd2d">&nbsp;台数</td>
              <td  style="background-color:#a3c1dd2d">&nbsp;性能系数（COP）</td>
              <td  style="background-color:#a3c1dd2d"></td>
              <td  style="background-color:#a3c1dd2d"></td>
            </tr>
            <tr v-for="(item, index) in jizu5" :key="'5'+index">
              <th scope="row">
                <textarea type="text" v-model="item.unitType" placeholder="——" />
              </th>
              <td>
                <textarea
                  type="text"
                  v-model="item.unitMeasure"
                  placeholder="——"
                />
              </td>
              <td>
                <textarea type="text" v-model="item.unitNum" placeholder="——" />
              </td>
              <td>
                <textarea type="text" v-model="item.unitErr" placeholder="——" />
              </td>
              <td v-if="index == 0" :rowspan="jizu5.length">
                <el-select
                  v-model="item.evaluationWay"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>

              <td v-if="index == 0" :rowspan="jizu5.length">
                  <el-select
                  v-model="item.evaInstitu"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="item.evaInstitu"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2" :rowspan="jizu1.length + 1" scope="row"  style="background-color:#a3c1dd2d">
                冷水（热泵）机组
                <div class="zengjia">
                  <el-button type="primary" @click="addjizu(2)">
                    增加 冷水（热泵）机组
                  </el-button>
                </div>
              </td>
              <td  style="background-color:#a3c1dd2d">&nbsp;类型</td>
              <td  style="background-color:#a3c1dd2d">&nbsp; 单机额定制冷量（kW）</td>
              <td  style="background-color:#a3c1dd2d">&nbsp;台数</td>
              <td  style="background-color:#a3c1dd2d">&nbsp;性能系数（COP）</td>
              <td  style="background-color:#a3c1dd2d"></td>
              <td  style="background-color:#a3c1dd2d"></td>
            </tr>
            <tr v-for="(item, index) in jizu1"  :key="'1'+index">
              <th scope="row">
                <textarea type="text" v-model="item.unitType" placeholder="——" />
              </th>
              <td>
                <textarea
                  type="text"
                  v-model="item.unitMeasure"
                  placeholder="——"
                />
              </td>
              <td>
                <textarea type="text" v-model="item.unitNum" placeholder="——" />
              </td>
              <td>
                <textarea type="text" v-model="item.unitErr" placeholder="——" />
              </td>
              <td v-if="index == 0" :rowspan="jizu1.length">
                <el-select
                  v-model="item.evaluationWay"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>

              <td v-if="index == 0" :rowspan="jizu1.length">
                  <el-select
                  v-model="item.evaInstitu"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="item.evaInstitu"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td :rowspan="jizu3.length + 1" colspan="2"  style="background-color:#a3c1dd2d">
                多联式空调（热泵）机组
                <div class="zengjia">
                  <el-button type="primary" @click="addjizu(3)">
                    增加 多联式空调（热泵）机组
                  </el-button>
                </div>
              </td>
              <td  style="background-color:#a3c1dd2d">&nbsp;类型</td>
              <td  style="background-color:#a3c1dd2d">&nbsp;单机额定制冷量(kW)</td>
              <td  style="background-color:#a3c1dd2d">&nbsp;台数</td>
              <td  style="background-color:#a3c1dd2d">&nbsp;综合性能系数(IPLV)</td>
              <td  style="background-color:#a3c1dd2d"></td>
              <td  style="background-color:#a3c1dd2d"></td>
            </tr>
            <tr v-for="(item, index) in jizu3" :key="'3'+index" >
              <th scope="row">
                <textarea type="text" v-model="item.unitType" placeholder="——" />
              </th>
              <td>
                <textarea
                  type="text"
                  v-model="item.unitMeasure"
                  placeholder="——"
                />
              </td>
              <td>
                <textarea type="text" v-model="item.unitNum" placeholder="——" />
              </td>
              <td>
                <textarea type="text" v-model="item.unitErr" placeholder="——" />
              </td>
              <td v-if="index == 0" :rowspan="jizu3.length">
                <el-select
                  v-model="item.evaluationWay"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td v-if="index == 0" :rowspan="jizu3.length">
                  <el-select
                  v-model="item.evaInstitu"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="item.evaInstitu"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2" :rowspan="jizu6.length + 1" scope="row"  style="background-color:#a3c1dd2d">
                转速可控型房间空调器
                <div class="zengjia">
                  <el-button type="primary" @click="addjizu(4)">
                    增加转速可控型房间空调器
                  </el-button>
                </div>
              </td>
              <td  style="background-color:#a3c1dd2d">&nbsp;类型</td>
              <td  style="background-color:#a3c1dd2d">&nbsp; 单机额定制冷量（kW）</td>
              <td  style="background-color:#a3c1dd2d">&nbsp; 台数</td>
              <td  style="background-color:#a3c1dd2d">&nbsp;能效比（EER）</td>
              <td  style="background-color:#a3c1dd2d"></td>
              <td  style="background-color:#a3c1dd2d"> </td>
            </tr>
            <tr v-for="(item, index) in jizu6"  :key="'6'+index">
              <th scope="row">
                <textarea type="text" v-model="item.unitType" placeholder="——" />
              </th>
              <td>
                <textarea
                  type="text"
                  v-model="item.unitMeasure"
                  placeholder="——"
                />
              </td>
              <td>
                <textarea type="text" v-model="item.unitNum" placeholder="——" />
              </td>
              <td>
                <textarea type="text" v-model="item.unitErr" placeholder="——" />
              </td>
              <td v-if="index == 0" :rowspan="jizu6.length">
                <el-select
                  v-model="item.evaluationWay"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td v-if="index == 0" :rowspan="jizu6.length">
                  <el-select
                  v-model="item.evaInstitu"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="item.evaInstitu"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td rowspan="11" scope="row"  style="background-color:#a3c1dd2d">集中冷热源与管网</td>
              <td colspan="2" rowspan="2" scope="row"  style="background-color:#a3c1dd2d">&nbsp; 锅炉</td>
              <td colspan="2"  style="background-color:#a3c1dd2d">类型&nbsp;</td>
              <td colspan="2"  style="background-color:#a3c1dd2d">&nbsp; 额定热效率（%）</td>
              <td  style="background-color:#a3c1dd2d"></td>
              <td  style="background-color:#a3c1dd2d"></td>
            </tr>
            <tr>
              <th colspan="2" scope="row">
                <textarea
                  type="text"
                  v-model="formtable.boilerType"
                  placeholder="——"
                />
              </th>
              <td colspan="2">
                <textarea
                  type="text"
                  v-model="formtable.rateEffic"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_rateEffic"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_rateEffic"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_rateEffic"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td colspan="2" rowspan="2" scope="row"  style="background-color:#a3c1dd2d">户式燃气炉</td>
              <td colspan="2"  style="background-color:#a3c1dd2d">类型&nbsp;</td>
              <td colspan="2"  style="background-color:#a3c1dd2d">&nbsp; 额定热效率（%）</td>
              <td style="background-color:#a3c1dd2d"></td>
              <td style="background-color:#a3c1dd2d"></td>
            </tr>
            <tr>
              <th colspan="2" scope="row">
                <textarea
                  type="text"
                  v-model="formtable.houseType"
                  placeholder="——"
                />
              </th>
              <td colspan="2">
                <textarea
                  type="text"
                  v-model="formtable.houseEffic"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_houseEffic"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_houseEffic"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_houseEffic"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">
                集中供暖系统热水循环水泵的耗电输热比
              </td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.focusRatio"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_focusRatio"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_focusRatio"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_focusRatio"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">热量表</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.heatMeater"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_heatMeater"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_heatMeater"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_heatMeater"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">分户温控及计量</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.temMeasure"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_temMeasure"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_temMeasure"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_temMeasure"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">水力平衡&nbsp;</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.waterBalence"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_waterBalence"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                </el-select>
              </td>
              <td>

                  <el-select
                  v-model="formtable.evaInstitu_waterBalence"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_waterBalence"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">自动控制装置</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.autoControl"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_autoControl"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_autoControl"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <textarea
                  type="text"
                  v-model="formtable.evaInstitu_autoControl"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">自动监测与控制</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.detectSystem"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_detectSystem"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_detectSystem"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_detectSystem"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">供热量控制</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.heatControl"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_heatControl"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_heatControl"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_heatControl"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" rowspan="6" style="background-color:#a3c1dd2d">供暖与空调系统</td>
              <td colspan="2" style="background-color:#a3c1dd2d">&nbsp;不应采用直接电热供暖/冷</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.noDirectHeat"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_noDirectHeat"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_noDirectHeat"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_noDirectHeat"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">冷/热水系统的输送能效比</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.waterTransAutio"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_waterTransAutio"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_waterTransAutio"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_waterTransAutio"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">空调冷热源机房的自动控制系统</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.airAutoSystem"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_airAutoSystem"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                  <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_airAutoSystem"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_airAutoSystem"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">分时分室控制设施</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.apartMeasure"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_apartMeasure"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_apartMeasure"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_apartMeasure"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">分户热量计量装置</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.apartHeatUnit"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_apartHeatUnit"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_apartHeatUnit"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_apartHeatUnit"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color:#a3c1dd2d">水力平衡措施</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.waterMeasure"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_waterMeasure"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_waterMeasure"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_waterMeasure"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color:#a3c1dd2d">照明系统</td>
              <td colspan="2" style="background-color:#a3c1dd2d">照度和照明功率密度</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.lightDensity"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_lightDensity"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_lightDensity"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_lightDensity"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color:#a3c1dd2d">生活热水系统</td>
              <td colspan="2" style="background-color:#a3c1dd2d">太阳能热水系统</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.gatherHot"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_gatherHot"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <el-select
                  v-model="formtable.evaInstitu_gatherHot"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select>
                <!-- <input
                  type="text"
                  v-model="formtable.evaInstitu_gatherHot"
                  placeholder="直接输入分值（示例：5）"
                /> -->
              </td>
            </tr>
            <tr>
              <th scope="row" rowspan="14" >选择项&nbsp;</th>
              <td rowspan="4" style="background-color:#a3c1dd2d">&nbsp;可再生能源</td>
              <td style="background-color:#a3c1dd2d">&nbsp;太阳能热水系统</td>
              <td style="background-color:#a3c1dd2d">集热效率&nbsp;</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.heatAuito"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_heatAuito"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_heatAuito"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_heatAuito"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color:#a3c1dd2d">可再生能源发电系统&nbsp;</td>
              <td style="background-color:#a3c1dd2d">&nbsp;比例</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.renewRatio"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_renewRatio"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_renewRatio"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_renewRatio"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color:#a3c1dd2d">&nbsp;地源热泵系统</td>
              <td style="background-color:#a3c1dd2d">&nbsp;比例</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.earthRatio"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_earthRatio"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_earthRatio"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_earthRatio"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color:#a3c1dd2d">风冷热泵系统</td>
              <td style="background-color:#a3c1dd2d">&nbsp;比例</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.windSystem"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_windSystem"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_windSystem"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_windSystem"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;自然通风</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.natureWind"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_natureWind"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_natureWind"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_natureWind"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;自然采光</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.natureLight"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_natureLight"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_natureLight"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_natureLight"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;有效遮阳措施</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.lightMeasure"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_lightMeasure"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_lightMeasure"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_lightMeasure"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;余热作为太阳能热水辅助热源</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.heatAid"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_heatAid"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_heatAid"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_heatAid"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;余热作为冬季供暖热源</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.winterHeat"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_winterHeat"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_winterHeat"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_winterHeat"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;能量回收系统</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.powerBack"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_powerBack"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_powerBack"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_powerBack"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">冷热源设备能效等级&nbsp;</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.energyGrade"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_energyGrade"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_energyGrade"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_energyGrade"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;供暖空调负荷预测功能</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.heatFore"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_heatFore"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_heatFore"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_heatFore"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;冷冻水出水温度阶段性调整</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.coldTemAdjust"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_coldTemAdjust"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_coldTemAdjust"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_coldTemAdjust"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color:#a3c1dd2d">&nbsp;其他新型节能措施</td>
              <td colspan="4">
                <textarea
                  type="text"
                  v-model="formtable.otherMeasure"
                  placeholder="——"
                />
              </td>
              <td>
                <el-select
                  v-model="formtable.evaluationWay_otherMeasure"
                  placeholder="——"
                  clearable
                >
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                   <el-option label="——" value="8"></el-option>
                </el-select>
              </td>
              <td>
                  <!-- <el-select
                  v-model="formtable.evaInstitu_otherMeasure"
                  clearable
                  placeholder="——"
                >
                  <el-option label="符合要求" value="符合要求"></el-option>
                  <el-option label="不符合要求" value="不符合要求"></el-option>
                  <el-option label="——" value="——"></el-option>
                </el-select> -->
                <input
                  type="text"
                  v-model="formtable.evaInstitu_otherMeasure"
                  placeholder="直接输入分值（示例：5）"
                />
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="10">
                <div class="minyong">
                  <p>1、民用建筑能效测评结论：</p>
                  <p>
                    （1）经软件模拟该项目基础相对节能率为<input
                      type="text"
                      v-model="formtable.relateEnergy"
                      placeholder="请输入内容"
                    />%,(设计标准为
                    <span class="minyong_select">
                      <el-select v-model="formtable.designStand">
                        <el-option
                          value="1"
                          label="《江苏省居住建筑热环境与节能设计标准》DGJ32/J71-2014"
                        ></el-option>
                        <el-option
                          value="2"
                          label="《江苏省居住建筑热环境与节能设计标准》DB32/ 4066-2021）"
                        ></el-option>
                      </el-select>
                    </span>
                    <span> ); </span>
                  </p>
                  <p>
                    （2）经测评，该项目规定项<input
                      type="text"
                      v-model="formtable.strip"
                      placeholder="请输入内容"
                    />条参评，均满足《民用建筑能效测评标识标准》DB32/T
                    3964-2020规定要求；
                  </p>
                  <p>
                    （3）经测评，该项目选择项<input
                      v-model="formtable.selectStrip"
                      type="text"
                      placeholder="请输入内容"
                    />条参评，加分<input
                      v-model="formtable.grades"
                      type="text"
                    />分；
                  </p>
                  <p>
                    （4）经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T
                    3964-2020标准要求，建筑节能率为<input
                      type="text"
                      v-model="formtable.buildEnergyRatio"
                      placeholder="请输入内容"
                    />%，测评合格。
                  </p>
                  <p>2、民用建筑能效标识建议：</p>
                  <p>
                    依据民用建筑能效测评结论，建议该建筑能效标识为
                     <el-select v-model="form.stars">
                          <el-option
                            :label="'一'"
                            :value="1"
                          ></el-option>
                          <el-option
                            :label="'二'"
                            :value="2"
                          ></el-option>
                          <el-option
                            :label="'三'"
                            :value="3"
                          ></el-option>
                        </el-select>星。
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row" colspan="4">测评人员&nbsp;</th>
              <th colspan="4">&nbsp;审核人员</th>
              <th colspan="4">&nbsp;批准人员</th>
            </tr>
          <tr class="table_select">
              <th colspan="4" scope="row">
                <el-select
                  v-model="formtable.evaluatUser"
                  v-loadmore="getStaffList"
                  :filter-method="fliterStaff"
                  filterable
                  placeholder="盖章/签字"
                  @focus="selectFocus(1)"
                >
                  <el-option
                    v-for="item in staffList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <!--              <input v-model="formtable.evaluatUser" placeholder="盖章/签字" type="text">-->
              </th>
              <td colspan="4">
                <el-select
                  v-model="formtable.auditUser"
                  v-loadmore="getStaffList"
                  :filter-method="fliterStaff"
                  filterable
                  placeholder="盖章/签字"
                  @focus="selectFocus(2)"
                >
                  <el-option
                    v-for="item in staffList2"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <!--              <input v-model="formtable.auditUser" placeholder="盖章/签字" type="text">-->
              </td>
              <td colspan="4">
                <el-select
                  v-model="formtable.approveUser"
                  v-loadmore="getStaffList"
                  :filter-method="fliterStaff"
                  filterable
                  placeholder="盖章/签字"
                  @focus="selectFocus(3)"
                >
                  <el-option
                    v-for="item in staffList3"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <!--              <input v-model="formtable.approveUser" placeholder="盖章/签字" type="text">-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="uploadtable">
        <el-row :gutter="20">
          <el-col :span="14"
            ><div class="grid-content bg-purple">
              <div class="gezi">
                <el-form-item label="上传能效测评报告">
                  <el-upload
                    class="upload-demo"
                    action="https://api.gbservice.cn/api/public/bupload/"
                    accept=".docx,.pdf"
                    :on-success="fileUploadSuccess"
                    :on-preview="downloadFile"
                    :file-list="fileList"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <span slot="tip" class="el-upload__tip">
                      选择上传文件（仅限pdf/docx、点击已上传文件名可下载）
                    </span>
                  </el-upload>
                </el-form-item>
              </div>
               <div class="gezi">
                <el-form-item label="上传附件">
                  <el-upload
                    :file-list="photo"
                    :on-success="photo_success"
                    :on-preview="downloadFile"
                    action="https://api.gbservice.cn/api/public/bupload/"
                    class="upload-demo"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <span slot="tip" class="el-upload__tip">
                      选择上传文件
                    </span>
                  </el-upload>
                </el-form-item>
                <div class="fileright">
                  （注：请将能耗计算模型、建筑、暖通和电气图纸打包上传）
                </div>
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <div class="gezi name_input">
                <el-form-item label="能效测评报告编号">
                  <el-input v-model="project.number"></el-input>
                </el-form-item>
              </div></div
          ></el-col>
        </el-row>
      </div>
    </el-form>
    <div class="comit">
      <div class="baocun">
        <el-button type="primary" @click="pre_page">上一页</el-button>
      </div>
       <div class="baocun">
        <el-button @click="saveProject" type="primary">保存</el-button>
      </div>
      <div class="baocun">
        <el-button type="primary" @click="next_page">生成《申报指标情况表》</el-button>
      </div>

      <!--      <div class="baocun">-->
      <!--        <el-button type="primary" @click="saveProject(2)">提交</el-button>-->
      <!--      </div>-->
    </div>
    </div>

  </div>
</template>
<script>
import { editTableProject, projectTableInfo } from "../../../../api/project";
import JiangSu from "../../../../assets/jiangsu.json";
import { getStaff } from "../../../../api/user";
import axios from "axios";

export default {
  data() {
    return {
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      formtable: {
        evaluationWay_heatEnergy: "1",
        evaluationWay_wallGas: "5",
        evaluationWay_hotPart: "5",
        evaluationWay_doorHeat: "5",
        evaluationWay_wallOpenArea: "5",
        evaluationWay_outShade: "5",
        evaInstitu_wallGas: "符合要求",
        evaInstitu_hotPart: "符合要求",
        // evaInstitu_hotPart: "符合要求",
        evaInstitu_doorHeat: "符合要求",
        evaInstitu_wallOpenArea: "符合要求",
        evaInstitu_outShade: "符合要求",
        evaluationWay_lightDensity: "7",
        evaInstitu_lightDensity: "符合要求",
        evaluationWay_gatherHot: "5",
        evaInstitu_gatherHot: "符合要求",
      },
      form: {
        stars: 1,
      },
      cityOptions: JiangSu.city,
      countyOptions: [],
      weatherOptions: [
        {
          name: "办公建筑",
          id: 1,
        },
        {
          name: "商场建筑",
          id: 2,
        },
        {
          name: "宾馆饭店建筑",
          id: 3,
        },
        {
          name: "文化教育建筑",
          id: 4,
        },
        {
          name: "医疗卫生建筑",
          id: 5,
        },
        {
          name: "其他建筑",
          id: 6,
        },
      ],

      photo: [],

      //冷水热泵机组
      jizu5: [{}],

      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu1: [{}, {}, {}],

      jizu3: [{}, {}, {}, {}],
      jizu6: [{}],
      project: [],

      project_id: "",

      staffList: [],
      staffList2: [],
      staffList3: [],
      staffPage: 2,
      staffPage2: 2,
      staffPage3: 2,
      activeStaff: 1,
      tmp_files: [],
    };
  },

  mounted() {
    this.getAllStaffList();

    let project_id = this.$route.query.project_id;
    this.project_id = project_id;
    this.getInfo();
     this.getCache();
      this.onBlur();
  },

  methods: {
    downloadFile(file){
      console.log(file);
      let url = file.url;
      let fileName = file.name;
      axios.get(url, { responseType: 'blob', params: {}}).then((res) => {
        if (!res.data) {
          this.$message.warning(fileName + "下载失败！");
          return
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([res.data]), fileName);
        } else {
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      }).catch(() => {
        this.$message.warning(file + "下载失败！");
      });

    },
      // 返回
    goback(){
    this.$router.push('/user_mark/itemdeclare')
    },
     //设置失去焦点事件
    onBlur() {
      let that = this;
      let formDOM = document.getElementById("form");
      console.log(formDOM);
      let input = formDOM.getElementsByTagName("input");
      console.log(input);
      if (input && input.length > 0) {
        for (let i = 0; i < input.length; i++) {
          input[i].onblur = function () {
            that.saveCache();
          };
        }
      }
    },
    //自动保存填写的信息到缓存中
    saveCache() {
      if (this.toCache) {
        console.log("保存到缓存");
        if (this.form) {
          localStorage.setItem("itde_form", JSON.stringify(this.form));
        }
        if (this.fileList) {
          localStorage.setItem("itde_fileList", JSON.stringify(this.fileList));
        }
        if (this.photo) {
          localStorage.setItem("itde_photo", JSON.stringify(this.photo));
        }
      }
    },
    //清除缓存
    clearCache() {
      localStorage.removeItem("itde_form");
      localStorage.removeItem("itde_fileList");
      localStorage.removeItem("itde_photo");
    },

    //获得缓存中的信息
    getCache() {
      let form = localStorage.getItem("itde_form");
      if (form && form != "") {
        this.form = JSON.parse(form);
      }
      let fileList = localStorage.getItem("itde_fileList");
      if (fileList && fileList != "") {
        this.fileList = JSON.parse(fileList);
      }
      let photo = localStorage.getItem("itde_photo");
      if (photo && photo != "") {
        this.photo = JSON.parse(photo);
      }
    },
    handleSelectChange() {
      this.$forceUpdate()
    },
    /**
     * 筛选员工
     */
    fliterStaff(searchVlaue) {
      this.staffList = [];
      this.staffPage = 1;
      this.getStaffList(searchVlaue);
    },

    //select聚焦
    selectFocus(active) {
      this.activeStaff = active;
      this.getStaffList();
    },

    getAllStaffList(){
      let params = {
        token: this.$store.state.token,
        limit: 1000,
        page: 1,
      };
      getStaff(params).then((res) => {
        if (res.data && res.data.length > 0) {
          this.staffList = res.data;
          this.staffList2 = res.data;
          this.staffList3 = res.data;
        }else {
          if (params.page==1){
            this.$message.warning("请先进入管理账户管理添加人员")
          }
        }
      });
    },

    //获得员工信息
    getStaffList(val = "") {
      console.log(this.activeStaff);
      let that = this;
      let params = {
        token: this.$store.state.token,
        limit: 1000,
      };
      if (this.activeStaff == 1) {
        params.page = this.staffPage;
      } else if (this.activeStaff == 2) {
        params.page = this.staffPage2;
      } else {
        params.page = this.staffPage3;
      }
      if (val != "") {
        params.name = val;
      }
      getStaff(params).then((res) => {
        if (res.data && res.data.length > 0) {
          if (this.activeStaff == 1) {
            that.staffList = that.staffList.concat(res.data);
            that.staffPage += 1;
          } else if (this.activeStaff == 2) {
            that.staffList2 = that.staffList2.concat(res.data);
            that.staffPage2 += 1;
          } else {
            that.staffList3 = that.staffList3.concat(res.data);
            that.staffPage3 += 1;
          }
          this.handleSelectChange();
        }
      });
    },

    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.form = res.data;

        //获取文件
        let file1 = [];
        let file2 = [];
        if (this.form.files && this.form.files.length > 0) {
          file1 = this.form.files
            .map((item) => {
              if (item.type == 3) {
                item.name = item.file;
                return item;
              }
            })
            .filter((d) => d);
        }
        this.fileList = file1;
        if (this.form.files && this.form.files.length > 0) {
          file2 = this.form.files
            .map((item) => {
              if (item.type == 4) {
                item.name = item.file;
                return item;
              }
            })
            .filter((d) => d);
        }
        this.photo = file2;

        let pre_files = this.form.files;
        let tmp_files = [];
        for (let i = 0; i < pre_files.length; i++) {
          if (pre_files[i].type != 3 && pre_files[i].type != 4) {
            tmp_files.push(pre_files[i]);
          }
        }
        this.tmp_files = tmp_files;
        console.log(tmp_files);

        if (that.form.detail){
          //value为对象
          Object.keys(that.form.detail).map((item) => {
            if (!that.form.detail[item] || that.form.detail[item] == "") {
              delete that.form.detail[item];
            }
            return true;
          });
        }

        that.formtable = Object.assign(that.formtable, that.form.detail);
        if (!that.formtable) {
          that.formtable = {};
        }
        let project = {
          name: that.form.name,
          building_area: that.form.building_area,
          build_company: that.form.build_company,
          address: that.form.address,
          weather: that.form.weather,
          design_company: that.form.design_company,
          manger_company: that.form.manger_company,
          work_build: that.form.work_build,
          number: that.form.number,
          floor:that.form.floor,
        };
        that.project = project;
        let jizu_total = that.form.jizhu;
        let jizu1 = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];

        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu1.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        if(jizu1.length==0){
          jizu1.push({
            unitType: "——",
            unitMeasure: "——",
            unitNum:"——",
            unitErr: "——"
          })
        }
        if (jizu2.length==0){
          jizu2.push({
            unitType: "——",
            unitMeasure: "——",
            unitNum:"——",
            unitErr: "——"
          })
        }
        if (jizu3.length==0){
          jizu3.push({
            unitType: "——",
            unitMeasure: "——",
            unitNum:"——",
            unitErr: "——"
          })
        }
        if (jizu4.length==0){
          jizu4.push({
            unitType: "——",
            unitMeasure: "——",
            unitNum:"——",
            unitErr: "——"
          })
        }
        if (jizu5.length==0){
          jizu5.push({
            unitType: "——",
            unitMeasure: "——",
            unitNum:"——",
            unitErr: "——"
          })
        }
        if (jizu6.length==0){
          jizu6.push({
            unitType: "——",
            unitMeasure: "——",
            unitNum:"——",
            unitErr: "——"
          })
        }
        that.jizu1 = jizu1;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;
      });
    },

    //上一页，保存
    pre_page() {
      let that = this;
      let params = {};
      params.jizu4 = this.jizu4;
      params.jizu2 = this.jizu2;
      params.jizu5 = this.jizu5;
      params.jizu1 = this.jizu1;
      params.jizu3 = this.jizu3;
      params.jizu6 = this.jizu6;
      params.detail = this.formtable;
      params.project = this.project;
      params.id = this.project_id;
      params.project_id = this.project_id;
      params.files = [];
      params.save = 1;
      params.token = this.$store.state.token;
      if (this.fileList && this.fileList.length > 0) {
        params.files = this.fileList.map((item) => {
          item.type = 3;
          item.eid = undefined;
          item.file = undefined;
          item.id = undefined;
          item.status = undefined;
          item.uid = undefined;
          return item;
        });
      }
      if (this.photo && this.photo.length > 0) {
        for (let i = 0; i < this.photo.length; i++) {
          this.photo[i].type = 4;
          this.photo[i].eid = undefined;
          this.photo[i].file = undefined;
          this.photo[i].id = undefined;
          this.photo[i].status = undefined;
          this.photo[i].uid = undefined;
        }
        params.files = params.files.concat(this.photo);
      }

      if (this.tmp_files && this.tmp_files.length > 0) {
        params.files = params.files.concat(this.tmp_files);
      }
      if (params.files && params.files.length > 0) {
        for (let i = 0; i < params.files.length; i++) {
          if (!params.files[i].name) {
            params.files[i].name = params.files[i].url;
          }
          params.files[i].eid = undefined;
          params.files[i].file = undefined;
          params.files[i].id = undefined;
          params.files[i].status = undefined;
          params.files[i].uid = undefined;
        }
      }
      editTableProject(params)
        .then((res) => {
          console.log(res);
          that.$router.push({
            path: "./itdeAdd?project_id=" + that.project_id,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //下一页
    next_page() {

      if (!this.formtable.evaluatUser || this.formtable.evaluatUser.replace(/\s/g,'')==''){
        this.$message.warning("请选择测评人员");
        return false;
      }
      if (!this.formtable.auditUser || this.formtable.auditUser.replace(/\s/g,'')==''){
        this.$message.warning("请选择审核人员");
        return false;
      }
      if (!this.formtable.approveUser || this.formtable.approveUser.replace(/\s/g,'')==''){
        this.$message.warning("请选择批准人员");
        return false;
      }

      let that = this;
      let params = {};
      params.jizu4 = this.jizu4;
      params.jizu2 = this.jizu2;
      params.jizu5 = this.jizu5;
      params.jizu1 = this.jizu1;
      params.jizu3 = this.jizu3;
      params.jizu6 = this.jizu6;
      params.detail = this.formtable;
      params.project = this.project;
      params.id = this.project_id;
      params.project_id = this.project_id;
      params.files = [];
      params.save = 1;
      params.token = this.$store.state.token;
      if (this.fileList && this.fileList.length > 0) {
        params.files = this.fileList.map((item) => {
          item.type = 3;
          item.eid = undefined;
          item.file = undefined;
          item.id = undefined;
          item.status = undefined;
          item.uid = undefined;
          return item;
        });
      }
      if (this.photo && this.photo.length > 0) {
        for (let i = 0; i < this.photo.length; i++) {
          this.photo[i].type = 4;
          this.photo[i].eid = undefined;
          this.photo[i].file = undefined;
          this.photo[i].id = undefined;
          this.photo[i].status = undefined;
          this.photo[i].uid = undefined;
        }
        params.files = params.files.concat(this.photo);
      }

      if (this.tmp_files && this.tmp_files.length > 0) {
        params.files = params.files.concat(this.tmp_files);
      }
      if (params.files && params.files.length > 0) {
        for (let i = 0; i < params.files.length; i++) {
          if (!params.files[i].name) {
            params.files[i].name = params.files[i].url;
          }
          params.files[i].eid = undefined;
          params.files[i].file = undefined;
          params.files[i].id = undefined;
          params.files[i].status = undefined;
          params.files[i].uid = undefined;
        }
      }
      editTableProject(params)
        .then((res) => {
          console.log(res);
          that.$router.push({
            path: "./nextTableTwo?project_id=" + that.project_id,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //增加 冷水（热泵）机组
    addjizu(which) {
      if (which == 1) {
        this.jizu5.push({});
      } else if (which == 2) {
        this.jizu1.push({});
      } else if (which == 3) {
        this.jizu3.push({});
      } else {
        this.jizu6.push({});
      }
    },

    //图片上传成功
    photo_success(re, file, fileList) {
      console.log(fileList);
      this.photo = fileList.map((item) => {
        return item.response.data;
      });
    },

    fileUploadSuccess(response, file, fileList) {
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList = tmpList.map((item) => {
        return item.response.data;
      });
      console.log(this.fileList);
    },

    //保存项目
    saveProject() {
      let that = this;
      let params = {};
      params.jizu4 = this.jizu4;
      params.jizu2 = this.jizu2;
      params.jizu5 = this.jizu5;
      params.jizu1 = this.jizu1;
      params.jizu3 = this.jizu3;
      params.jizu6 = this.jizu6;
      params.detail = this.formtable;
      params.project = this.project;
      params.id = this.project_id;
      params.project_id = this.project_id;
      params.files = [];
      params.save = 1;
      params.token = this.$store.state.token;
      if (this.fileList && this.fileList.length > 0) {
        params.files = this.fileList.map((item) => {
          item.type = 3;
          item.eid = undefined;
          item.file = undefined;
          item.id = undefined;
          item.status = undefined;
          item.uid = undefined;
          return item;
        });
      }
      if (this.photo && this.photo.length > 0) {
        for (let i = 0; i < this.photo.length; i++) {
          this.photo[i].type = 4;
          this.photo[i].eid = undefined;
          this.photo[i].file = undefined;
          this.photo[i].id = undefined;
          this.photo[i].status = undefined;
          this.photo[i].uid = undefined;
        }
        params.files = params.files.concat(this.photo);
      }

      if (this.tmp_files && this.tmp_files.length > 0) {
        params.files = params.files.concat(this.tmp_files);
      }
      if (params.files && params.files.length > 0) {
        for (let i = 0; i < params.files.length; i++) {
          if (!params.files[i].name) {
            params.files[i].name = params.files[i].url;
          }
          params.files[i].eid = undefined;
          params.files[i].file = undefined;
          params.files[i].id = undefined;
          params.files[i].status = undefined;
          params.files[i].uid = undefined;
        }
      }
      editTableProject(params)
        .then((res) => {
          console.log(res);
           that.$message.success(res.message || "保存成功");
          // that.$router.push({
          //   path: "./itemdeclare",
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
      console.log(this.fileList);
    },
    handleRemove(file) {
      console.log(file, "2");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file, "1");
    },
  },
};
</script>
<style scoped>
th{
  background-color: #a3c1dd2d;
}
input{
    background-color: #f4f4f4f4;
}
textarea {
  width: 99%;
  height: 40px;
  border:none;
  text-align: center;
  line-height: 40px;
  overflow: visible;
  font-size: 14px;
  word-break: break-all;
  background-color: #f4f4f4f4;
}
textarea::-webkit-scrollbar{
  display: none;
}
/deep/.minyong .el-select{
width: 335px !important;
}
/deep/.engerytable .el-select{
width: 100%;
}
/deep/input::placeholder{
  color: #66b1ff;
}
/deep/textarea::placeholder{
  color: #66b1ff;
}
/deep/.engerytable .el-input--suffix .el-input__inner {
  width: 100%;
  border: 0;
  text-align: center;
}
/deep/.engerytable .el-input__suffix {
  right: 0px;
}
.table_select .el-select {
  width: 100%;
}
/deep/ .table_select .el-input {
  width: 100%;
}
/deep/ .minyong input {
  width: 255px;
  border-bottom: 1px solid #333 !important;
}
/* /deep/ .el-input {
  width: 250px;
} */
/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}
/deep/ .minyong .minyong_select input {
  width: 335px;
}
/deep/.minyong .el-input__suffix {
  right: 13px;
}
</style>
<style lang="scss" scoped>
input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}
.engerytable {
  margin: 20px;
}
.coianter {
  background-color: #ffffff;
  // height: 100%;
}

.idteail {
  margin: 4px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #f4f4f4f4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
    cursor: pointer;
  }
}

.gezi {
  // margin: 20px 0;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;

  .geziright {
    // margin-left: 20px;
  }

  .fileright {
    display: flex;
    flex-direction: row-reverse;
    color: #3086fb;
    font-size: 14px;
    cursor: pointer;
     margin-top: 10px;
  }
}

.tableform {
  margin: 34px 100px;
}

.comit {
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .baocun {
    margin: 0 25px;
    display: flex;
    flex-direction: row;
  }
}
td {
  height: 40px;
}
// input {
//   height: 40px;
//   width: 98%;
//   border: 0;
// }

.zengjia {
  margin: 10px 0;
}
.dtcontent{
  height: 87vh;
  overflow-y: scroll;
}
  .dtcontent::-webkit-scrollbar {
      display: none;
    }
</style>
